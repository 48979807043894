// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@import "~@angular/material/theming";

@import "~@ng-select/ng-select/themes/default.theme.css";
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat-core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$qayimdactory-admin-angular-primary: mat-palette($mat-indigo);
$qayimdactory-admin-angular-accent: mat-palette($mat-pink, A200, A100, A400);

// The warn palette is optional (defaults to red).
$qayimdactory-admin-angular-warn: mat-palette($mat-red);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$qayimdactory-admin-angular-theme: mat-light-theme(
  (
    color: (
      primary: $qayimdactory-admin-angular-primary,
      accent: $qayimdactory-admin-angular-accent,
      warn: $qayimdactory-admin-angular-warn,
    ),
  )
);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include angular-material-theme($qayimdactory-admin-angular-theme);

/* You can add global styles to this file, and also import other style files */

// html, body { height: 100%; }
// body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }
@import "./assets/scss/custome";


.btn.loading {
  background-color: rgb(3, 114, 189);
  padding-right: 40px;
  cursor: not-allowed;
}
.btn.loading:after {
  content: "";
  position: absolute;
  border-radius: 100%;
  right: 6px;
  top: 50%;
  width: 0px;
  height: 0px;
  margin-top: -2px;
  border: 2px solid rgba(255,255,255,0.5);
  border-left-color: #FFF;
  border-top-color: #FFF;
  animation: spin .6s infinite linear, grow .3s forwards ease-out;
}
.view-text-ar{
  direction: rtl !important
}
@keyframes spin {
  to {
      transform: rotate(359deg);
  }
}
@keyframes grow {
  to {
      width: 14px;
      height: 14px;
      margin-top: -8px;
      right: 13px;
  }
}
